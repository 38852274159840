import excApp from '@adobe/exc-app';

import { EnvMappingKey, envMapping } from '../constants';
import { getRegionFromIMSProfile } from './getIntermediaryHeaders';

export const requestUrl = (baseUrl: string, prefix: string, endpoint: string) => {
  if (endpoint.startsWith('/')) {
    return `${baseUrl}${endpoint}`;
  }
  if (!prefix) {
    return `${baseUrl}/${endpoint}`;
  }
  return `${baseUrl}/${prefix}/${endpoint}`;
};

export const getMaestroStandaloneUrl = (endpointPrefix?: string) => {
  const runtime = excApp();
  const env = runtime.lastConfigurationPayload?.environment as EnvMappingKey;
  const region = getRegionFromIMSProfile();
  const prefix = endpointPrefix ? `/${endpointPrefix}` : '';

  return `https://${region}.${envMapping[env]}${prefix}`;
};
