import excApp from '@adobe/exc-app';
import user from '@adobe/exc-app/user';
import { getIsInGenStudio, getIsMaestroStandalone } from '@wf-mfe-maestro/navigation';
import { getTreatmentIsEnabled$ } from '@wf-mfe/toggles';

import { getCookie } from './utils/getCookie';
import MaestroAPI from './api';
import StandAloneApiClient from './api/StandAloneApiClient';
import WorkfrontApiClient from './api/WorkfrontApiClient';
import { ENDPOINT_PREFIX, envMappingToAdobeIOUrl } from './constants';
import { isValidEnvironment } from './utils/environmentChecks';
import { getMaestroStandaloneUrl } from './utils/requestUrl';

/**
 * TODO:
 * dispatch event about request status
 */

export const getAuth = async () =>
  Promise.all([user.get('imsToken'), user.get('imsOrg'), user.get('imsProfile')]).then(
    ([token, org, profile]) => ({ token, org, profile })
  );

let client;

let isLoadPerformanceEnabled: boolean = false;
getTreatmentIsEnabled$('planning-load-performance-in-genstudio').subscribe((isEnabled: boolean) => {
  isLoadPerformanceEnabled = isEnabled;
});

if (getIsMaestroStandalone()) {
  const runtime = excApp();
  const env = runtime.lastConfigurationPayload?.environment;

  const reviewAppId =
    getCookie('REVIEW_APP_ID') ||
    new URLSearchParams(runtime.lastConfigurationPayload?.externalQueryParams).get('REVIEW_APP_ID');

  isValidEnvironment(env);
  client = new StandAloneApiClient(
    isLoadPerformanceEnabled
      ? getMaestroStandaloneUrl(ENDPOINT_PREFIX)
      : envMappingToAdobeIOUrl[env],
    '/planning/attachments',
    isLoadPerformanceEnabled ? '' : 'planning',
    'v1',
    // apiKeys used here are configured in adobe.io
    getIsInGenStudio() ? 'maestro-genstudio' : 'maestro',
    getAuth,
    reviewAppId
  );
} else if (process.env.USE_MOCK_BACKEND_URL) {
  // avoid loading the class itself, if USE_MOCK_BACKEND is not set
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { MockApiClient } = require('./api/MockApiClient');
  client = new MockApiClient('', ENDPOINT_PREFIX, process.env.USE_MOCK_BACKEND_URL);
} else {
  client = new WorkfrontApiClient('', ENDPOINT_PREFIX);
}

export const api = new MaestroAPI(client);

export * from './types';
export * from './emitter';
export * from './enums';
export * from './errors';
export { MaestroStorage } from './utils/MaestroStorage';
export {
  generateIntermediaryId,
  getIntermediaryHeader,
  getGatewayHeader,
  intermediaryId,
} from './utils/getIntermediaryHeaders';
export { getAdobeIOConfigForGenstudio } from './utils/getAdobeIOConfigForGenstudio';
