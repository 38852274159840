export const ENDPOINT_PREFIX = 'maestro/api/internal';

export const envMappingToAdobeIOUrl = {
  dev: 'https://workfront-dev.adobe.io',
  qa: 'https://workfront-qa.adobe.io',
  stage: 'https://workfront-stage.adobe.io',
  prod: 'https://workfront.adobe.io',
} as const;

export const envMapping = {
  dev: 'devtest.workfront-dev.com',
  qa: 'devtest.workfront-dev.com',
  stage: 'devtest.workfront-dev.com',
  prod: 'my.workfront.com',
};

export type EnvMappingKey = keyof typeof envMapping;
